.cart {
  min-height: 100vh;
  width: 100%;
  background-color: #0a0909;
  padding: 10rem 6.5rem 8rem 6.5rem;
  column-gap: 8rem;
  overflow: hidden;
  @media screen and (max-width: 45rem) {
    // display: block;
    flex-direction: column;
    padding: 10rem 6.5rem 8rem 6.5rem;
  }
  &__det {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
  }

  &__userWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #101114;
    border-radius: 20px;
    padding: 1rem 3rem;
    box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: 0.16);
    margin-bottom: 5rem;
  }

  &__user {
    display: flex;
    align-items: center;
    column-gap: 1rem;

    &-img {
      height: 10rem;
      width: 10rem;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__products {
    // display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 3rem;
    row-gap: 2rem;
  }

  &__prod {
    display: flex;
    flex-direction: column;
    background-color: #101114;
    border-radius: 20px;
    box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: 0.16);
    padding: 2rem 6rem 1rem 6rem;
    align-items: center;
    width: 100%;

    &-img {
      height: 70px;
      object-fit: contain;
    }
  }

  &__total {
    width: 80%;
    height: 100%;
    @media only screen and (max-width: 900px) {
      width: 100%;
    }

    &-head {
      padding: 5rem;
    }

    &-details {
      padding: 5rem;
      background-color: #0b0b0b;
      width: 100%;
    }

    &-item {
      display: flex;
      padding-bottom: 2.5rem;
      border-bottom: 1px solid #1c2131;
      padding-top: 0;
      align-items: center;

      &:not(:first-child) {
        padding-top: 2.5rem;
      }

      &--imgBox {
        height: 10rem;
        width: 10rem;
        padding: 1rem 2rem;
        border-radius: 20px;
        background-color: #101114;
        box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: 0.16);
        margin-right: 3rem;
      }

      &--img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    &-ship {
      display: flex;
      justify-content: space-between;
      margin: 2rem 0;
      padding-bottom: 2rem;
      border-bottom: 1px solid #1c2131;
    }

    &-amount {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4rem;
    }

    &-btnWrapper {
      display: flex;
      justify-content: center;
    }
  }
}
