.merchFin {
    height: 100vh;
    width: 100vw;
    background-color: #0A0909;
    padding: 8rem 5rem;

    &__container {
        display: flex;
        flex-direction: column;
        background-color: #101114;
        border-radius: 20px;
        padding: 4rem;
        width: 100%;
        height: 100%;
        align-items: center;
    }

    &__imgBox {
        height: 15rem;
        width: 20rem;
        border-radius: 20px;        
        padding: 1.5rem 2rem;
        box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: .16);
        margin-bottom: 2rem;
    }

    &__img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}