.pay {
  background-color: #0a0909;
  min-height: 100vh;
  @media screen and (max-width: 45rem) {
    height: 100vh;
  }

  &__container {
    display: flex;
    width: 100%;
    height: 100%;
    column-gap: 10rem;
  }

  &__ship {
    padding: 2rem 2rem 2rem 2rem;
    background-color: #101114;
    border-radius: 20px;
    width: 100%;
  }

  &__map {
    height: 29rem;
    object-fit: contain;
    width: 100%;
  }

  &__det {
    padding: 2rem 2rem 6rem 2rem;
    background-color: #101114;
    border-radius: 20px;
    width: 100%;
    height: 100%;
  }

  &__otherPay {
    display: flex;
    margin-top: 5rem;
    margin-bottom: 3rem;
    column-gap: 2rem;
  }

  &__payImg {
    height: 4rem;
    width: 13rem;
    object-fit: contain;
    cursor: pointer;
  }

  &__btnWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
