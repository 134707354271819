.payCard {
    height: 100vh;
    width: 100vw;
    background-color: #0A0909;
    padding: 8rem 5rem;

    &__container {
        display: flex;
        width: 100%;
        height: 100%;
        column-gap: 10rem;
    }

    &__ship {
        padding: 4.8rem 3.2rem 2.6rem 3.2rem;
        background-color: #101114;
        border-radius: 20px;
        width: 100%;
    }

    &__map {
        height: 29rem;
        object-fit: contain;
        width: 100%;
    }

    &__det {
        padding: 4.8rem 3.2rem 2.6rem 3.2rem;
        background-color: #101114;
        border-radius: 20px;
        width: 100%;
    }

    &__logo {
        height: 18rem;
        // width: 40rem;
        object-fit: contain;
        margin-bottom: 4rem;
    }

    &__inputGrp {
        display: flex;
        column-gap: 2rem;

        &:not(:last-child) {
            margin-bottom: 2rem;
        }
    }


}