body{
  background: #000;
}

.merch {
  background-color: #0a0909;
  height: inherit;
  width: 100%;
  position: relative;

  &__grp {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    column-gap: 10rem;
    @media screen and (max-width: 45rem) {
      top: 50%;
      row-gap: 8rem;
      flex-direction: column;
    }
  }

  &__card {
    background-color: #202021;
    border-radius: 20px;
    box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: 0.16);
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
    cursor: pointer;
    // min-height: 410px;
    @media screen and (max-width: 45rem) {
      padding: 1rem;
    }

    &-img {
      height: 200px;
      width: 100%;
      object-fit: contain;
      // margin-bottom: 2rem;
      border-radius: 1rem;
      @media screen and (max-width: 45rem) {
        height: 130px;
        width: 100%;
        //  color: rgba(12, 0, 0, 0.199)";
      }
    }
  }
}

// @media screen and (max-width: 450px) {
//   html {
//     font-size: 50%;
//   }
//   .merch {
//     &__grp {
//       //   flex-direction: column;
//       //   top: 75%;
//       //   left: 50%;
//       gap: 4rem;
//     }
//     // &-img {
//     //   height: 40rem;
//     //   width: 42rem;
//     // }
//   }
// }
@media only screen and (max-width: 1220px) {
  html {
    font-size: 45%;
  }
}
@media only screen and (max-width: 1200px) {
  html {
    font-size: 40%;
  }
}
@media only screen and (max-width: 1028px) {
  html {
    font-size: 38%;
  }
}
@media only screen and (max-width: 965px) {
  html {
    font-size: 35%;
  }
}
@media only screen and (max-width: 844px) {
  html {
    font-size: 43%;
  }
}

// //   @media only screen and (max-width: 820px) {
// //     font-size: 1%;
// //   }
// @media only screen and (max-width: 840px) {
//   html {
//     font-size: 71%;
//   }
// }
@media only screen and (max-width: 320px) {
  html {
    font-size: 68%;
  }
}
