.merchDet {
  width: 100%;

  background-color: #0a0909;
  padding: 10rem 6.5rem 0rem 6.5rem;
  column-gap: 8rem;

  &__slide {
    background-color: #101114;
    border-radius: 20px;
    padding: 6rem 10rem 6rem 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 900px) {
      padding: 3rem 5rem 2rem 5rem;
    }
    &-img {
      height: 400px;
      width: 100%;
      object-fit: contain;
      border-radius: 1rem;
      @media screen and (max-width: 900px) {
        margin-bottom: 2rem;
        height: 40rem;
        width: 100%;
      }
    }

    &-arr {
      height: 3rem;
      width: 3rem;
      object-fit: contain;
      cursor: pointer;
    }

    &-btn {
      column-gap: 4rem;
    }
  }

  &__details {
    width: 100%;
    margin-top: 4rem;
    @media screen and (max-width: 45rem) {
      width: 90%;
      margin-left: 3rem;
      margin-top: 3rem;
    }
    &-head {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      @media screen and (max-width: 45rem) {
        // justify-content: space-between;
      }
    }
  }

  &__list {
    margin-bottom: 4rem;
    list-style-position: inside;
  }
}

.new-arrival-section {
  background-color: #0a0909;
  padding: 2rem 6.5rem 8rem 6.5rem;
}
@media screen and (max-width: 45rem) {
  // html {
  //   font-size: 50%;
  // }
  .merchDet {
    display: block;
    padding-bottom: 55px;
  }
  // &-img {
  //   height: 44rem;
  //   width: 45rem;
  // }
}
