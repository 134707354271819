.merchTot {
  background-color: #0a0909;
  padding: 10rem 6.5rem 0rem 6.5rem;
  column-gap: 8rem;
  @media screen and (max-width: 45rem) {
    flex-direction: column;
    row-gap: 3rem;
    // margin-top: 2rem;
  }
  &__slide {
    background-color: #101114;
    border-radius: 20px;
    padding: 6rem 10rem 4rem 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 900px) {
      padding: 3rem 5rem 2rem 5rem;
      margin-top: 2rem;
    }

    &-img {
      height: 40rem;
      width: 41rem;
      object-fit: cover;
      margin-bottom: 6rem;
    }

    &-arr {
      height: 3rem;
      width: 3rem;
      object-fit: contain;
    }

    &-btn {
      display: flex;
      column-gap: 2rem;
    }
  }

  &__details {
    width: 100%;
  }

  &__colors {
    display: flex;
    column-gap: 2rem;
    margin-bottom: 5rem;
  }

  &__color {
    height: 5.5rem;
    width: 5.5rem;
    border-radius: 4px;
    border: 1px solid #ffffff;
    cursor: pointer;

    &-selected {
      border: 1px solid #ffffff;
      border-radius: 4px;
      height: 5.5rem;
      width: 5.5rem;
    }
  }
  &__size {
    font-family: "helveticaBold";
    font-size: 3rem;
    color: #fff;
    padding: 0.5rem 2rem;
    background-color: #101114;
    border-radius: 16px;
    cursor: pointer;
    &-selected {
      font-family: "helveticaBold";
      font-size: 3rem;
      color: #fff;
      padding: 0.5rem 2rem;
      background-color: #3c3c3d;
      border-radius: 16px;
      cursor: pointer;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    width: 60%;
  }

  &__sizes {
    display: flex;
    column-gap: 1rem;
    margin-bottom: 3rem;
    @media screen and (max-width: 900px) {
      margin-bottom: 4rem;
      font-size: 2.8rem;
    }
  }
  //   &__selected {
  //     border: 5px solid red;
  //   }
}
