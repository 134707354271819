@font-face {
  font-family: "pilatBold";
  src: url("../../assets/fonts/PilatExtended-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "pilatDemi";
  src: url("../../assets/fonts/PilatExtended-DemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "pilatHeavy";
  src: url("../../assets/fonts/PilatExtended-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: "pilatReg";
  src: url("../../assets/fonts/PilatExtended-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "helvetica";
  src: url("../../assets/fonts/Helvetica.ttf") format("truetype");
}

@font-face {
  font-family: "helveticaBold";
  src: url("../../assets/fonts/HelveticaBold.ttf") format("truetype");
}

.banner {
  &-lg {
    font-family: "pilatHeavy";
    font-size: 6rem;
    color: #ffa10b;
    text-transform: uppercase;
    margin-top: -1rem;
    margin-bottom: 1rem;

    @media only screen and (max-width: 1020px) {
      font-size: 4rem;
    }

    @media only screen and (max-width: 675px) {
      font-size: 6rem;
      margin-top: -0.5rem;
    }

    @media only screen and (max-width: 472px) {
      font-size: 2.2rem;
      margin-top: -0.5rem;
    }
  }

  &-sm {
    font-family: "pilatReg";
    font-size: 3.5rem;
    color: #fff;

    @media only screen and (max-width: 1020px) {
      font-size: 2rem;
    }

    @media only screen and (max-width: 675px) {
      font-size: 3rem;
    }

    @media only screen and (max-width: 472px) {
      font-size: 1.7rem;
    }
  }

  &-para {
    font-family: "pilatReg";
    font-size: 1.4rem;
    color: #ffffff;
    width: 44rem;
    margin-bottom: 3rem;

    @media only screen and (max-width: 1020px) {
      font-size: 1.2rem;
    }

    @media only screen and (max-width: 675px) {
      font-size: 2rem;
      width: 66rem;
    }

    @media only screen and (max-width: 655px) {
      // html {
      //   font-size: 25px;
      // }
      font-size: 3rem;
    }

    @media only screen and (max-width: 472px) {
      width: 29rem;
      font-size: 2rem;
    }

    @media only screen and (max-width: 320px) {
      width: 20rem;
    }
  }

  &-socialTitle {
    font-family: "pilatReg";
    font-size: 1rem;
    color: #ffffff;
    opacity: 0.6;
    line-height: 1.5rem;
    text-transform: uppercase;

    @media only screen and (max-width: 1060px) {
      font-size: 0.8rem;
    }

    @media only screen and (max-width: 675px) {
      font-size: 2.5rem;
    }

    @media only screen and (max-width: 450px) {
      font-size: 1.5rem;
    }
  }

  &-socialLink {
    &,
    &:link,
    &:visited {
      text-decoration: none;
      cursor: pointer;
      font-family: "pilatDemi";
      font-size: 1.3rem;
      letter-spacing: 2.5px;
      line-height: 3rem;
      color: #fff;
      text-transform: uppercase;
      transition: all 0.17s ease-in;

      @media only screen and (max-width: 1060px) {
        font-size: 1rem;
      }

      @media only screen and (max-width: 820px) {
        font-size: 0.8rem;
      }

      @media only screen and (max-width: 675px) {
        font-size: 0.6rem;
      }

      @media only screen and (max-width: 555px) {
        font-size: 0.5rem;
      }

      @media only screen and (max-width: 450px) {
        font-size: 1rem;
      }
    }

    &:hover {
      color: #ffa10b;
    }
  }

  &-videoTitle {
    &,
    &:link,
    &:visited {
      font-family: "pilatDemi";
      font-size: 1rem;
      letter-spacing: 1.5px;
      line-height: 1rem;
      color: #fff;
      width: 30rem;
      line-height: 1.5rem;
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.3s ease-in;

      @media only screen and (max-width: 910px) {
        font-size: 1.3rem;
      }

      @media only screen and (max-width: 840px) {
        width: 23rem;
      }

      @media only screen and (max-width: 820px) {
        font-size: 0.8rem;
      }

      @media only screen and (max-width: 655px) {
        width: 100%;
        font-size: 1rem;
      }
    }
  }
}
.nav {
  &-name {
    font-family: "helvetica";
    font-size: 2rem;
    line-height: 60px;
    color: #ffffff;
    text-transform: uppercase;
  }

  &-link {
    font-family: "helvetica";
    font-size: 1.5rem;
    // line-height: 60px;
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none;
    align-items: center;
    margin: 0 auto;
  }
}

.merch {
  &-bgTxt {
    font-family: "helvetica";
    text-transform: uppercase;
    text-align: center;
    // line-height: 100px;
    // position: absolute;
    color: #646464;
    opacity: 0.21;
    // font-size: 23rem;
    // padding-top: 100px;
  }

  &-cate {
    font-family: "helvetica";
    font-size: 1.7rem;
    line-height: 2rem;
    color: #ffffff;
    @media screen and (max-width: 45rem) {
      font-size: 2rem;
      line-height: 2.1rem;
    }
  }

  &-name {
    font-family: "helveticaBold";
    font-size: 1.8rem;
    line-height: 2rem;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (max-width: 45rem) {
      font-size: 2rem;
      // line-height: 2rem;
    }
  }

  &-price {
    font-family: "helvetica";
    font-size: 2rem;
    line-height: 2.2rem;
    color: #ffffff;
  }
  // @media screen and (max-width: 45rem) {
  //   font-size: 1rem;
  // }
}

.merchDet {
  &-title {
    font-family: "helvetica";
    font-size: 3rem;
    line-height: 3rem;
    color: #ffffff;
    text-transform: uppercase;
    @media screen and (max-width: 45rem) {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }

  &-price {
    font-family: "helvetica";
    font-size: 2.2rem;
    line-height: 2rem;
    color: #ffffff;
    width: 130px;
    text-align: right;
    @media screen and (max-width: 45rem) {
      // margin-left: 3rem;
    }
  }

  &-cate {
    font-family: "helvetica";
    font-size: 1.7rem;
    line-height: 2rem;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 2rem;
    // @media screen and (max-width: 45rem) {
    //   font-size: 3rem;
    //   line-height: 3rem;
    //   margin-top: 2rem;
    // }
  }

  &-info {
    font-family: "helvetica";
    font-size: 2.5rem;
    line-height: 4rem;
    color: #ffffff;
  }

  &-listLines {
    font-family: "helvetica";
    font-size: 1.7rem;
    line-height: 2rem;
    color: #ffffff;
    margin-bottom: 3rem;
  }
}

.merchTot {
  &-title {
    font-family: "helvetica";
    font-size: 2rem;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 1rem;
    @media screen and (max-width: 900px) {
      // margin-top: 5rem;
      font-size: 2.3rem;
    }
  }

  &-titleReg {
    font-family: "helvetica";
    font-size: 2rem;
    line-height: 20px;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 1rem;
    @media screen and (max-width: 900px) {
      margin-top: 2rem;
      font-size: 2.8rem;
    }
  }
}

.merchFin {
  &-reg {
    font-family: "helvetica";
    font-size: 2.8rem;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 2rem;
  }

  &-bold {
    font-family: "helvetica";
    font-size: 2.8rem;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 2rem;
  }

  &-sm {
    font-family: "helvetica";
    font-size: 2rem;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 2rem;
  }

  &-tin {
    font-family: " ";
    font-size: 1.4rem;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 2rem;
  }

  &-tinBold {
    font-family: "helvetica";
    font-size: 1.4rem;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 2rem;
  }

  &-med {
    font-family: "helvetica";
    font-size: 2rem;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 2rem;
  }

  &-col {
    font-family: "helvetica";
    font-size: 2rem;
    line-height: 20px;
    color: #e07418;
    margin-bottom: 2rem;
  }
}

.pay {
  &-title {
    font-family: "helvetica";
    font-size: 2rem;
    line-height: 30px;
    color: #ffffff;
    margin-bottom: 2rem;
  }

  &-location {
    font-family: "helvetica";
    font-size: 1.5rem;
    line-height: 30px;
    color: #ffffff;
    padding: 1.5rem 8rem 1.7rem 8rem;
    background-color: #1c1c1c;
    border-radius: 25px;
    max-width: max-content;
    // text-align: center;
    margin: auto;
    margin-bottom: 1.5rem;
  }

  &-medTitle {
    font-family: "helvetica";
    font-size: 1.8rem;
    line-height: 30px;
    color: #ffffff;
  }

  &__input {
    font-family: "helvetica";
    font-size: 2rem;
    line-height: 30px;
    color: #ffffff;
    background-color: #1c1c1c;
    border-radius: 25px;
    padding: 1.5rem 8rem 1.7rem 8rem;
    border: none;
    margin: 0;
    width: 70%;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &_news_input {
    font-family: "helvetica";
    font-size: 1.5rem;
    line-height: 15px;
    color: #ffffff;
    background-color: #1c1c1c;
    border-radius: 25px;
    padding: 1.5rem 2rem 1.7rem 3rem;
    border: none;
    margin: 0;
    width: 70%;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}

.payCard {
  &__input {
    font-family: "helvetica";
    font-size: 2rem;
    line-height: 30px;
    color: #ffffff;
    background-color: #1c1c1c;
    border-radius: 25px;
    padding: 1.5rem 8rem 1.7rem 8rem;
    border: none;
    margin: 0;
    width: 100%;
  }

  &__inputSmall {
    font-family: "helvetica";
    font-size: 2rem;
    line-height: 30px;
    color: #ffffff;
    background-color: #1c1c1c;
    border-radius: 25px;
    padding: 1.5rem 4rem 1.7rem 4rem;
    border: none;
    margin: 0;
    width: 100%;
  }
}

.cart {
  &-uName {
    font-family: "helvetica";
    font-size: 2.7rem;
    color: #fff;
    line-height: 25px;
  }

  &-uNum {
    font-family: "helvetica";
    font-size: 1.9rem;
    color: #fff;
    line-height: 25px;
  }

  &-title {
    font-family: "helvetica";
    font-size: 2rem;
    color: #fff;
    margin-bottom: 2rem;
  }

  &-btn {
    font-family: "helvetica";
    font-size: 2rem;
    color: #fff;
    margin-top: 3rem;
  }

  &-prodName {
    font-family: "helvetica";
    font-size: 2rem;
    color: #fff;
    line-height: 3rem;
  }

  &-prodCate {
    font-family: "helvetica";
    font-size: 1.4rem;
    color: #fff;
    line-height: 2rem;
    @media only screen and (max-width: 900px) {
      font-size: 1.6rem;
    }
  }

  &-prodCateReg {
    font-family: "helvetica";
    font-size: 1.4rem;
    color: #fff;
    line-height: 20px;
    @media only screen and (max-width: 900px) {
      font-size: 2rem;
    }
  }

  &-price {
    font-family: "helvetica";
    font-size: 1.8rem;
    color: #fff;
    line-height: 20px;
    margin-top: 0.5rem;
  }

  &-ship {
    font-family: "helvetica";
    color: #fff;
    font-size: 1.8rem;
  }

  &-shipPrice {
    font-family: "helvetica";
    color: #fff;
    font-size: 1.8rem;
  }

  &-total {
    font-family: "helvetica";
    color: #fff;
    font-size: 2rem;
  }
}

@font-face {
  font-family: "reeyRegular";
  src: url("../../assets/fonts/reeyRegular.otf") format("truetype");
}

@font-face {
  font-family: "sfProBold";
  src: url("../../assets/fonts/sfProBold.otf") format("truetype");
}

@font-face {
  font-family: "sfProRegular";
  src: url("../../assets/fonts/sfProRegular.otf") format("truetype");
}

.tempHome {
  &-heading {
    font-family: "reeyRegular", sans-serif;
    font-size: 4rem;
    color: #7e7e7e;
    white-space: nowrap;
    line-height: 18rem;

    @media only screen and (max-width: 500px) {
      font-size: 3.2rem;
      line-height: 10rem;
    }
  }
}

.pwredBy {
  &-reg {
    font-family: "sfProRegular", sans-serif;
    color: #fff;
    line-height: 2.1rem;
    font-size: 1.6rem;
  }

  &-bold {
    &--wh {
      font-family: "sfProBold", sans-serif;
      color: #fff;
      line-height: 2.1rem;
      font-size: 1.6rem;
    }

    &--col {
      font-family: "sfProBold", sans-serif;
      color: #32bbe6;
      line-height: 2.1rem;
      font-size: 1.6rem;
    }
  }
}
