*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; //10px/16px = 62.5% (1rem = 10px)
  scroll-behavior: smooth;
  background-color: black;
}

.container {
  display: grid;
  grid-template-columns:
    [leftgutter-start] minmax(3.6rem, 1fr) [leftgutter-end full-start] repeat(
      12,
      [col-start] minmax(min-content, 1fr) [col-end]
    )
    [full-end rightgutter-start] minmax(3.6rem, 1fr) [rightgutter-end];
  grid-gap: 1.6;
}

.nav {
  &-menu-item,
  :active,
  ::before,
  ::after {
    background: black !important;
    border: none;
  }
  &-menu-item:hover {
    background: rgb(37, 37, 37) !important;
  }
  &-menu-button:hover {
    background: rgb(37, 37, 37) !important;
  }
}
