@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(3rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

.hideAnimation {
    transform: translateX(-100%);
}

.showAnimation{
    transform: translateX(100%);
}