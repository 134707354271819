.btn {

    &__header {
        font-size: 1.2rem;
        color: #fff;
        line-height: 3.2rem;
        text-decoration: none;
        letter-spacing: 8.52px;
        background-color: transparent;
        outline: none;
        text-transform: uppercase;
        opacity: 1;
        font-family: 'pilatBold';

        border: none;
        padding: 2.5rem 5rem;
        position: relative;
        cursor: pointer;

        @media only screen and (max-width: 675px) {            
          padding: 1rem 1.5rem;
          font-size: .8rem;
        }

        &::after,
        &::before{
            content: '';
            display: block;
            position: absolute;
            width: 70%;
            height: 40%;
            border: 2px solid;
            transition: all 0.6s ease-in;
        }

        &::after{

            bottom: 0;
            right: 0;
            border-top-color: transparent;
            border-left-color: transparent;
            border-bottom-color: #CE8828;
            border-right-color: #CE8828;
            // border-bottom-right-radius: 40px ;
            // border-bottom-left-radius: 0;
        }

        &::before{

            top: 0;
            left: 0;
            border-bottom-color: transparent;
            border-right-color: transparent;
            border-top-color: #CE8828;
            border-left-color: #CE8828;
        }  

        &:hover:after,
        &:hover:before{
            width: 100%;
            height: 100%;
            border-radius: 4rem;

        }
    }

    &__play {
        outline: none;
        text-decoration: none;
        background-color: transparent;
        border-radius: 50%;
        // padding: 2.8rem;
        height: 6rem;
        width: 6rem;
        cursor: pointer;
        position: relative;
        border: none;
        
        & svg {
            height: 6rem;
            width: 6rem;
        }

        &-main {
            transition: all .2s ease-in;
        }

        &:hover &-main {
            fill: #CE8828;
        }

    }


    &-primary {
      &,
      &:link,
      &:visited {
         background-color: #202021;
         border-radius: 6px;
         border: none;
         padding: 1.5rem 3.5rem 1.7rem 3.6rem;
         font-family: 'helveticaBold';
         font-size: 1.9rem;
         color: #fff;
         text-transform: uppercase;
         cursor: pointer;
      }
    }

    &-color {
      &,
      &:link,
      &:visited {
         background-color: #C2282B;
         border-radius: 6px;
         border: none;
         padding: 1.5rem 3.5rem 1.7rem 3.6rem;
         font-family: 'helveticaBold';
         font-size: 1.9rem;
         color: #fff;
         text-transform: uppercase;
         cursor: pointer;
      }
    }

    &-text {
      &,
      &:link,
      &:visited {
         background-color: transparent;
         border: none;
         font-family: 'helvetica';
         font-size: 2.7rem;
         color: #fff;
         cursor: pointer;
      }
    }

    &-trans {
      &,
      &:link,
      &:visited {
         background-color: transparent;
         border-radius: 6px;
         border: 1px solid #707070;
         padding: 1.5rem 3.5rem 1.7rem 3.6rem;
         font-family: 'helveticaBold';
         font-size: 1.9rem;
         color: #fff;
         text-transform: uppercase;
         cursor: pointer;
      }
    }

}

// Spin animation
@keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  .stroke-dotted {
    opacity: 0;
    stroke-dasharray: 4,5;
    stroke-width: 1px;
    transform-origin: 50% 50%;
    animation: spin 4s infinite linear;
    transition: opacity 1s ease, 
                stroke-width 1s ease;
  }
  .stroke-solid {
    stroke-dashoffset: 0;
    stroke-dashArray: 300;
    stroke-width: 4px;
    transition: stroke-dashoffset 1s ease, 
                opacity 1s ease;
  }
  
  .icon {
    transform-origin: 50% 50%;
    transition: transform 200ms ease-out;
  }
  
  // Hover
  #play:hover {
    .stroke-dotted {
      stroke-width: 4px;
      opacity: 1;
    }
    .stroke-solid {
      opacity: 0;
      stroke-dashoffset: 300;
    }
    .icon {
      transform: scale(1.05);
    }
  }
  
  #play {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
  
