.home {
  height: 100vh;
  width: 100vw;

  &__banner {
    height: 100%;
    width: 100%;
    position: relative;

    padding: 0 7.6rem;
    padding-top: 10rem;

    clear: both;

    @media only screen and (max-width: 1320px) {
      padding-top: 8rem;
    }

    @media only screen and (max-width: 730px) {
      padding: 0 3rem;
      padding-top: 8rem;
    }

    &-overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba($color: #22252f, $alpha: 0.6);
      z-index: 2;
    }

    &-content {
      width: max-content;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: absolute;
      bottom: 3rem;
      right: 7.6rem;
      z-index: 3;

      @media only screen and (max-width: 1110px) {
        width: 100vw;
        right: 0;
        padding: 0 7.6rem;
      }

      @media only screen and (max-width: 730px) {
        padding: 0 3rem;
      }
    }

    &-socials {
      display: flex;
      margin-top: 7.5rem;
      @media only screen and (max-width: 1090px) {
        width: 100%;
        justify-content: space-between;
      }

      @media only screen and (max-width: 1060px) {
        justify-content: space-between;
      }

      @media only screen and (max-width: 450px) {
        flex-direction: column;
        margin-top: 3.5rem;
      }
    }

    &-social {
      display: flex;

      &:not(:last-child) {
        margin-right: 2rem;

        @media only screen and (max-width: 1060px) {
          margin-right: 0;
        }

        @media only screen and (max-width: 450px) {
          margin-bottom: 3rem;
        }
      }

      &--text {
        display: flex;
        flex-direction: column;
      }

      & svg {
        margin-right: 1.4rem;

        @media only screen and (max-width: 902px) {
          margin-right: 0.5rem;
        }
      }
    }

    &-video {
      &,
      &:link,
      &:visited {
        text-decoration: none;
      }

      &:hover p {
        color: #ffa10b;
      }
      @media only screen and (max-width: 450px) {
        margin-bottom: 10rem;
      }
    }

    &-videos {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media only screen and (max-width: 655px) {
        flex-direction: column;
      }

      @media only screen and (max-width: 655px) {
        margin-bottom: 4rem;
      }
    }

    &-video {
      display: flex;
      align-items: center;

      & button {
        margin-right: 2rem;
      }

      &:not(:last-child) {
        @media only screen and (max-width: 655px) {
          margin-bottom: 1rem;
        }
      }
    }

    &-bgVideo {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      z-index: 1;
    }

    &-cta {
      position: relative;
      z-index: 3;
      width: 100%;

      @media only screen and (max-width: 1320px) {
        margin-bottom: 5rem;
      }

      @media only screen and (max-width: 1020px) {
        margin-bottom: 15rem;
      }

      @media only screen and (max-height: 676px) {
        margin-bottom: 15rem;
        margin-top: 6rem;
      }

      @media only screen and (max-width: 655px) {
        margin-bottom: 5rem;
        margin-top: 5rem;
      }
    }

    &-contact {
      position: absolute;
      top: 0;
      right: 0;
      padding-right: 10px;

      &::after {
        content: "";
        height: 100%;
        width: 3px;
        background-color: #ffa10b;
        position: absolute;
        top: 0;
        right: 0;
      }
      @media only screen and (max-width: 965px) {
        display: none;
      }
    }
  }
}
