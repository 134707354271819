.tempHome {
    grid-column: leftgutter-start / rightgutter-end;

    height: 100vh;
    width: 100%;
    position: relative;
    background-color: #F1F2F3;

    @media only screen and (max-width: 500px) {
        margin-bottom: 10rem;
    }

    &__backBg {
        height: 100vh;
        width: 100vw;
        object-fit: cover;
        position: fixed;
        top: 0;
        right: 0;
        object-position: bottom right;
        // z-index: 1;
        background-color: #F1F2F3;

        @media only screen and (max-width: 900px) {
            object-position: bottom center;

        }
    }

    &__frontBg {
        height: 20rem;
        width: 100vw;
        object-fit: cover;
        position: fixed;
        bottom: 0;
        left: 0;
        object-position: bottom left;
        // z-index: 3;

        @media only screen and (max-height: 750px) {
            display: none;

            
        }

    }

    &__head {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: baseline;
        padding-top: 3rem;
        padding-left: 3rem;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3;
        @media only screen and (max-width: 600px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-left: 0;
            justify-content: center;
        }

          @media only screen and (max-width: 500px) {
            background-image:  linear-gradient(to bottom, rgba($color: #F1F2F3, $alpha: 1), rgba($color: #F1F2F3, $alpha: .9)) ;

        }
    }

    &__logo {
        height: 8rem;
        @media only screen and (max-width: 500px) {
            height: 10rem;
            width: 10rem;
            object-fit: contain;
        }
    }

    &-btn {
        &,
        &:link,
        &:visited {
            text-decoration: none;            
            display: inline-block;            
            border-radius: 22px;        
            cursor: pointer;
            height: 7rem;
            width: 20rem;
            padding: 1.5rem 3rem;            

            box-shadow: 0 3px 6px rgba($color: #000000, $alpha: .16);
            border: none;
            background-image: url(../../assets/img/icons/NicePng_snow-png-transparent_601073.png);
            background-color: rgba($color: #fff, $alpha: .7);
            margin-bottom: 4rem;
            transition: all .2s ease-in;
            margin-right: 3rem;

            @media only screen and (max-width: 500px) {
                width: 30rem;

            }

        }

        &:hover {
            transform: translateY(-.7rem);
        }
    }

    &__socialLogo {
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center;

        
    }

    // &__container {
    //     min-width: max-content;
    //     min-height: max-content;
    //     position: relative;
    //     z-index: 2;
    //     overflow: hidden;
    // }

    &__btnWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 50%;
        margin: auto;
        margin-top: 26rem;
        padding-bottom: 10rem;

        @media only screen and (max-width: 1100px) {
            width: 70%;
        }

        @media only screen and (max-width: 900px) {
            margin-top: 35rem;
        }

        @media only screen and (max-width: 750px) {
            width: 90%;
        }

        @media only screen and (max-width: 600px) {
            grid-template-columns: 1fr 1fr;
        }

        @media only screen and (max-width: 500px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                min-width: max-content;
                margin-top: 28rem;

            }
    }

    &__arrBoard {
        position: fixed;
        bottom: -1rem;
        left: -40rem;
        z-index: 4;
        cursor: pointer;

        &-wrapper {
            height: 100%;
            width: 100%;
            position: relative;
        }

        @media only screen and (max-width: 600px) {
            display: none;
        }

        @media only screen and (max-height: 750px) {
            height: 20rem;
        }
    }

    &__arrBoardImg {
        height: 100%;
        width: 100%;
        object-fit: contain;

    }

    &__dialogueBox {
        height: 50rem;
        width: 70rem;
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: -25rem; /* Negative half of height. */
        margin-left: -35rem; /* Negative half of width. */  
        display: none;
        z-index: 5;
    }

    &__dialogueImg {
        height: 100%;
        width: 100%;
        object-fit: contain;
        
    }

    &__pwredBy {
        position: fixed;
        bottom: 3rem;
        right: 3rem;
        z-index: 5;
        text-shadow: 0 3px 6px rgba($color: #000000, $alpha: .16);
        & span {
            display: inline-block;
        }
    }
}
